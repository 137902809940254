import classnames from "classnames"
import styles from "./block-header.module.css"

export default function BlockHeader({ icon, heading, text, align, className }) {
  const hasText = text && text.length > 0
  const isEmpty = [icon, heading, text].filter(Boolean).length === 0

  if (isEmpty) {
    return null
  }

  const classes = [
    styles.wrapper,
    className,
    {
      "block-header": true,
      "no-text": !hasText,
      "no-icon": !icon,
      center: align === "center",
      "inner-column": align === "inner-column"
    }
  ]

  return (
    <div className={classnames(classes)}>
      <Icon icon={icon} />
      <h2>{heading}</h2>
      {hasText && (
        <div className="lead" dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </div>
  )
}

const Icon = ({ icon }) => {
  if (!icon) {
    return null
  }

  return <div className="icon" dangerouslySetInnerHTML={{ __html: icon }} />
}
