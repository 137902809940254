import classNames from "classnames"
import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import BlockHeader from "@/common/partials/block-header/block-header"
import styles from "./blurbs.module.css"

export default function Blurbs({ block }) {
  const { attrs, innerBlocks } = block
  const { bgColor, blockGap, heading, layout, text, align } = attrs

  if (innerBlocks.length === 0) {
    return null
  }

  return (
    <Block
      block={block}
      width="bleed"
      gap={blockGap ?? "normal"}
      className={classNames(
        styles.wrapper,
        "layout-" + layout,
        "bg-color-" + bgColor,
        {
          "has-white-theme": attrs.theme === "white"
        }
      )}>
      <div className="inner-wrapper">
        <BlockHeader
          align={align}
          heading={heading}
          text={text}
          className="col-span-4"
        />
        <div className="columns">
          <Blocks blocks={innerBlocks} />
        </div>
      </div>
    </Block>
  )
}
